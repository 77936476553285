import { ethers } from 'ethers'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { addresses } from 'src/constants';
import { IJsonRPCError } from 'src/slices/interfaces';
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { useWeb3Context } from '.';
import { abi as KillerPreSaleABI } from "../abi/KillerPreSale.json";
import { error } from "../slices/MessagesSlice";
import imageURL from '../assets/tokens/GNS.svg'
import { numberToHex } from 'src/helpers';

export default function useIdo() {

    const dispatch = useDispatch()
    const { address, provider, chainID } = useWeb3Context()
    const [ethBalance, setEthBalance] = useState('0')
    const [goal, setGoal] = useState(0)
    const [raised, setRaised] = useState(0)
    const [wBougth, setWBougth] = useState(0)
    const [pBougth, setPBougth] = useState(0)
    const [isWhited, setIsWhited] = useState(false)
    const [price, setPrice] = useState('0')
    const [roundTime, setRoundTime] = useState(<any>[])

    useEffect(() => {
        initData().then()
    }, [address, provider, chainID])

    const initData = useCallback(
        async () => {
            if (provider && chainID) {
                try {
                    const idoContract = new ethers.Contract(addresses[chainID].KillerPreSale, KillerPreSaleABI, provider)
                    const OGStartTime = await idoContract.OGStartTime()
                    const publicStartTime = await idoContract.publicStartTime()
                    const WLStartTime = await idoContract.WLStartTime()
                    const endTimestamp = await idoContract.endTimestamp()
                    setRoundTime([Number(OGStartTime), Number(WLStartTime), Number(publicStartTime), Number(endTimestamp)])
                    // const goal1 = await idoContract.wTotalAmount()
                    // const goal2 = await idoContract.pTotalAmount()
                    const now = Math.round(new Date().getTime() / 1000)
                    if (now < publicStartTime) {
                        if (now < WLStartTime) {
                            setPrice("0.8")
                        } else {
                            setPrice("0.9")
                        }
                        // setGoal(Number(ethers.utils.formatUnits(goal1, 18)))
                    } else {
                        setPrice("1")
                        // setGoal(Number(ethers.utils.formatUnits(goal1, 18)) + Number(ethers.utils.formatUnits(goal2, 18)))
                    }

                    const raised1 = await idoContract.wSaleAmount()
                    const raised2 = await idoContract.pSaleAmount()
                    setRaised(Number(ethers.utils.formatUnits(raised1, 18)) + Number(ethers.utils.formatUnits(raised2, 18)))
                    const wBougth = await idoContract.wBougthAmount(address)
                    const pBougth = await idoContract.pBougthAmount(address)
                    setWBougth(Number(ethers.utils.formatUnits(wBougth, 18)));
                    setPBougth(Number(ethers.utils.formatUnits(pBougth, 18)));

                    // const OGPrice = await idoContract.OGPrice()
                    // const WLPrice = await idoContract.WLPrice()
                    // const publicPrice = await idoContract.publicPrice()

                    // 获取钱包ETH
                    if (address) {
                        const signer = provider.getSigner()
                        const idoContract = new ethers.Contract(addresses[chainID].KillerPreSale, KillerPreSaleABI, signer)
                        const ethBalance = await provider.getBalance(address);
                        setEthBalance(ethers.utils.formatUnits(ethBalance, 18));
                        const isOG = await idoContract.whiteListedOG(address)
                        const isWL = await idoContract.whiteListedWL(address)
                        setIsWhited(isOG || isWL)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        [address, provider, chainID])

    // const addTokenToWallet = async () => {
    //     if (window.ethereum) {
    //         try {
    //             await window.ethereum.request({
    //                 method: "wallet_watchAsset",
    //                 params: {
    //                     type: "ERC20",
    //                     options: {
    //                         address: addresses[chainID].AlphaKiller,
    //                         symbol: 'aKILLER',
    //                         decimals: 9,
    //                         image: imageURL,
    //                     },
    //                 },
    //             });
    //         } catch (e: unknown) {
    //             const rpcError = e as IJsonRPCError;
    //             if (rpcError.data) {
    //                 dispatch(error(rpcError.data.message))
    //             } else {
    //                 dispatch(error(rpcError.message))
    //             }
    //         }
    //     }
    // }

    // Ido 用户eth换Killer
    const handlerBuy = useCallback(async (quantity) => {
        let tx;
        try {
            const signer = provider.getSigner();
            const idoContract = new ethers.Contract(addresses[chainID].KillerPreSale, KillerPreSaleABI, signer)
            const amountInWei = numberToHex(quantity, 18)
            tx = await idoContract.buy({ value: amountInWei })
            dispatch(
                fetchPendingTxns({ txnHash: tx.hash, text: "Buy_KILLER", type: "buy_killer" }),
            );
            await tx.wait();
            return tx
        } catch (e: unknown) {
            const rpcError = e as IJsonRPCError;
            console.log(rpcError.message)
            if (rpcError.data) {
                dispatch(error(rpcError.data.message))
            } else {
                dispatch(error(rpcError.message.substring(0, 100)))
            }
        } finally {
            if (tx) {
                initData().then()
                dispatch(clearPendingTxn(tx.hash));
            }
        }
    }, [address, provider, chainID])

    return { goal, raised, isWhited, ethBalance, price, handlerBuy, roundTime, wBougth, pBougth }
}



