import { useState, useEffect } from "react";
import { addresses, TOKEN_DECIMALS } from "../../constants";
import { getTokenImage } from "../../helpers";
import { abi } from "../../abi/OlympusStakingv2.json";
import { useDispatch, useSelector } from "react-redux";
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import "./menu.scss";
import { useWeb3Context } from "../../hooks/web3Context";

import SohmImg from "src/assets/images/token.png";
import { ethers } from "ethers";
import { getBalances } from "src/slices/AccountSlice";
import { error } from "src/slices/MessagesSlice";


function OhmMenu() {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID, address, provider } = useWeb3Context();

  const networkID = chainID;

  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[networkID].SOHM_ADDRESS;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClaimU = async () => {
    if (address && provider && chainID) {
      try {
        const signer = provider.getSigner()
        const contract = new ethers.Contract(addresses[chainID].OLDStaking, abi, signer)
        await contract.forfeit()
      } catch (error) {

      }
    }
  }


  const open = Boolean(anchorEl);
  const id = "gns-popper";
  // const daiAddress = dai.getAddressForReserve(networkID);
  // const fraxAddress = frax.getAddressForReserve(networkID);
  return (
    <Box
      component="div"
      onMouseEnter={e => handleClick(e)}
      onMouseLeave={e => handleClick(e)}
      id="gns-menu-button-hover"
    >
      <Button id="gns-menu-button" variant="contained" color="secondary" size="large" title="KILLER" aria-describedby={id}>
        <SvgIcon component={InfoIcon} color="primary" />
        <Typography>KILLER</Typography>
      </Button>

      {/* <Button id="gns-menu-button" size="large" variant="contained"  >
        <Typography> {ChainText[chainID]} </Typography>
      </Button> */}

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="gns-menu" elevation={1}>
                <Box component="div" className="buy-tokens">
                  <Link
                    href={`https://app.sushi.com/swap?inputCurrency=0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8&outputCurrency=0x9c53329161a587f7aC2236B93945A24bBEB5179b&chainId=42161`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Buy on Su Shi <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>

                  <Link
                    href={`https://dexscreener.com/arbitrum/0xaa69d8f585c1177fe093a9014f8012f9fd9d495e`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Charts <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>
                </Box>

                {isEthereumAPIAvailable ? (
                  <Box className="add-tokens">
                    <Divider color="secondary" />

                    <p>ADD TOKEN TO WALLET</p>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={addTokenToWallet("KILLER", OHM_ADDRESS, SohmImg)}
                    >
                      <Typography>KILLER</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={addTokenToWallet("sKILLER", SOHM_ADDRESS, SohmImg)}
                    >
                      <Typography>sKILLER</Typography>

                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={handleClaimU}
                    >
                      <Typography>Claim OLD Staking</Typography>

                    </Button>
                    {/* <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={addTokenToWallet("wKILLER", addresses[networkID].WSOHM_ADDRESS)}
                    >
                      <Typography>wKILLER</Typography>

                    </Button> */}
                  </Box>
                ) : null}

                {/* <Divider color="secondary" />
                <Link
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="large" variant="contained" color="secondary" fullWidth>
                    <Typography align="left">Unstake LP Token</Typography>
                  </Button>
                </Link> */}
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}



const addTokenToWallet = (tokenSymbol, tokenAddress, tokenImage) => async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: TOKEN_DECIMALS,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};




export default OhmMenu;
