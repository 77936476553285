// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 0.3;
export const TOKEN_DECIMALS = 9;


interface IAddresses {
  [key: number]: { [key: string]: string };
}
/*

*/
export const addresses: IAddresses = {
  42161: {
    OLDStaking:"0x7854bd0E074E21295a7De745ea811D6D4D472627",

    USDC_ADDRESS: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8", //
    DAO_ADDRESS: '0xD815061E401a40Cfc02248CD553D6F1c0C23d5bA',

    OHM_ADDRESS: "0x9c53329161a587f7ac2236b93945a24bbeb5179b", // 300463 

    STAKING_ADDRESS: "0x3ce725bDc3dc3e7bfD13e4E7A3D94787072A8e28",
    STAKING_HELPER_ADDRESS: "0x338C7d3cC872A6E9C688E90C63cbcf2e09C6c04d",
    SOHM_ADDRESS: "0x1E02F2E9e3829bb26E7e1c2E961FC916aFE0a39C",
    DISTRIBUTOR_ADDRESS: "0xa7d7f2e07c986955828D11feB598375C06Cf4449",
    BONDINGCALC_ADDRESS: "0x00b45c092864AA4b072A8B3cA0D13eC24D8b12b5",
    TREASURY_ADDRESS: "0xb4086d093faAC5419841f6acC7e7ECE45D0c5f51",
    LP_ADDRESS: '0xAA69D8F585C1177Fe093A9014f8012F9fd9D495e',
    REDEEM_HELPER_ADDRESS: "0x5248f438fa9a1b5f3019F2EfBfbF2B9e4FE4170B",
    WSOHM_ADDRESS: "0x9c53329161a587f7ac2236b93945a24bbeb5179b",

    KillerMigrate: '0x65BB3E3515C49193D3Db7fA8B6d1677a0852932D', //
    AlphaKiller: "0xba0Dff2393b6eCd9B1Db732Be02E7F35722ADAA1",
    KillerPreSale: "0x866aF6ca21F3F0c57bBeD2550219b213C247A985",
  },

  97: {
    OHM_ADDRESS: "0xd855e2B287006CEF9160a010A0107a2A23e97Ff3", //
    STAKING_ADDRESS: "0x488225Ce8DA21070bBa2493e683Cf9Be262811Ed",
    STAKING_HELPER_ADDRESS: "0x85ff5090Dea0C20902fceDba380aBaFb438B23a7",
    SOHM_ADDRESS: "0xB4f9A403ec52328DB08Dea5dA027Be0deD7b094e",
    DISTRIBUTOR_ADDRESS: "0x5036FC606c5202EB65F2d2086f362Ac8F53E5E1D",
    BONDINGCALC_ADDRESS: "0x22ef00e9671e4Fd60E6A9A738e36f15025BB4d96",
    TREASURY_ADDRESS: "0x9FFe58553084074fe842AD97E9B1607965C73AC6",
    USDC_ADDRESS: "0xf080Df19874C0b73B527A1c7142750CA2C05997A", //
    DAO_ADDRESS: '0xE6ce6781a8b1367A77D1494a6E57Ee8A0Ce64979',
    LP_ADDRESS: '0xD2d86dEf793B00861A554e7f17fb9665E99Fa19a',
    REDEEM_HELPER_ADDRESS: "0x607c3921371943adAAa06637dB96566DA1B2AdDC",
    // WSOHM_ADDRESS: "0x1fF23Eb6F7b7183045DE8d750f017009fE239EC2",

    alphaKiller: '0x4c0343c5605bd8039AD242C0d8B7211186Ec3253', //
    KillerMigrate: '0x1E1e8EfdB3e8A84CD726bADdeB090b2874c85907', //

    AlphaKiller: "0xba0Dff2393b6eCd9B1Db732Be02E7F35722ADAA1",
    KillerPreSale: "0x866aF6ca21F3F0c57bBeD2550219b213C247A985",
  }
};



