import { SvgIcon, Link } from "@material-ui/core";
import { ReactComponent as GitHub } from "../../assets/icons/github.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Temgran } from "../../assets/icons/Temgran.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";

export default function Social() {
  return (
    <div className="social-row" >
      <Link href="https://discord.com/invite/gnsfi" target="_blank">
        <SvgIcon color="action" style={{ color: "#D9D9D9" }} component={Discord} />
      </Link>

      <Link href="https://twitter.com/GNS_Fi" target="_blank">
        <SvgIcon color="primary" style={{ color: "#D9D9D9" }} component={Twitter} />
      </Link>

      {/* <Link href="https://github.com/KillerDAO2022" target="_blank">
        <SvgIcon  color="primary"  style={{color:"#D9D9D9"}} component={GitHub} />
      </Link>

      <Link href="https://medium.com/@KillerDAO" target="_blank">
        <SvgIcon color="primary" style={{color:"#D9D9D9"}} component={Medium} />
      </Link> */}
    </div>
  );
}
