// import SquareWOFF from "../assets/fonts/EuclidSquare-Regular.woff";
// import SquareBoldWOFF from "../assets/fonts/EuclidSquare-Bold.woff";
// import SquareSemiBoldWOFF from "../assets/fonts/EuclidSquare-SemiBold.woff";
// import SquareItalicWOFF from "../assets/fonts/EuclidSquare-Italic.woff";
// import SquareLightWOFF from "../assets/fonts/EuclidSquare-Light.woff";
// import SquareMediumWOFF from "../assets/fonts/EuclidSquare-Medium.woff";


import gilroy from "../assets/fonts/gilroy-bold-4.otf";
import gilroyExtraBold from "../assets/fonts/Gilroy-ExtraBold.otf";
import gilroyLight from "../assets/fonts/Gilroy-Light-11.otf";
import GilroyMedium from "../assets/fonts/Gilroy-Medium-2.otf";
import GilroyRegular from "../assets/fonts/gilroy-regular-3.otf";
import Radomir from "../assets/fonts/Radomir-Tinkov-Gilroy-Heavy-9.otf";

const gilroyFont = {
  fontFamily: "gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('EuclidSquare'),
		local('EuclidSquare-Regular'),
		url(${gilroy}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const gilroyExtraBoldFont = {
  fontFamily: "gilroyExtraBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('EuclidSquare'),
		local('EuclidSquare-Regular'),
		url(${gilroyExtraBold}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const gilroyLightFont = {
  fontFamily: "gilroyLight",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('EuclidSquare'),
		local('EuclidSquare-Regular'),
		url(${gilroyLight}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};


const fonts = [gilroyFont, gilroyExtraBoldFont, gilroyLightFont];

export default fonts;
